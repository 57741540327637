export const boothData = [
  {
    text: "G12",
    key: "G12",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "G11",
    key: "G11",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "G10",
    key: "G10",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "G09",
    key: "G09",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "G08",
    key: "G08",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "G07",
    key: "G07",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "G06",
    key: "G06",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "G05",
    key: "G05",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "G04",
    key: "G04",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "G03",
    key: "G03",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "G02",
    key: "G02",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "G01",
    key: "G01",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "F01",
    key: "F01",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "F02",
    key: "F02",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "F03",
    key: "F03",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "F04",
    key: "F04",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "F05",
    key: "F05",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "F06",
    key: "F06",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "F07",
    key: "F07",
    fee: "$1500",
    boothType_zh: "标准展位",
    color:'green'
  },
  {
    text: "F08",
    key: "F08",
    fee: "$1500",
    boothType_zh: "标准展位",
    color:'green'
  },
  {
    text: "F09",
    key: "F09",
    fee: "$1500",
    boothType_zh: "标准展位",
    color:'green'
  },
  {
    text: "F10",
    key: "F10",
    fee: "$1500",
    boothType_zh: "标准展位",
    color:'green'
  },
  {
    text: "F11",
    key: "F11",
    fee: "$1500",
    boothType_zh: "标准展位",
    color:'green'
  },
  {
    text: "F12",
    key: "F12",
    fee: "$1500",
    boothType_zh: "标准展位",
    color:'green'
  },
  {
    text: "F13",
    key: "F13",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "F14",
    key: "F14",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "F15",
    key: "F15",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "F16",
    key: "F16",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "F17",
    key: "F17",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "F18",
    key: "F18",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "F19",
    key: "F19",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "F20",
    key: "F20",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "F21",
    key: "F21",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "F22",
    key: "F22",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "F23",
    key: "F23",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "F24",
    key: "F24",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "F25",
    key: "F25",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "F26",
    key: "F26",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "F27",
    key: "F27",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E01",
    key: "E01",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E02",
    key: "E02",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E03",
    key: "E03",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E04",
    key: "E04",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E05",
    key: "E05",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E06",
    key: "E06",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E07",
    key: "E07",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E08",
    key: "E08",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E09",
    key: "E09",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E10",
    key: "E10",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E11",
    key: "E11",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E12",
    key: "E12",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E13",
    key: "E13",
    fee: "$1500",
    boothType_zh: "标准展位",
    color:'green'
  },
  {
    text: "E14",
    key: "E14",
    fee: "$1500",
    boothType_zh: "标准展位",
    color:'green'
  },
  {
    text: "E15",
    key: "E15",
    fee: "$1500",
    boothType_zh: "标准展位",
    color:'green'
  },
  {
    text: "E16",
    key: "E16",
    fee: "$1500",
    boothType_zh: "标准展位",
    color:'green'
  },
  {
    text: "E17",
    key: "E17",
    fee: "$1500",
    boothType_zh: "标准展位",
    color:'green'
  },
  {
    text: "E18",
    key: "E18",
    fee: "$1500",
    boothType_zh: "标准展位",
    color:'green'
  },
  {
    text: "E19",
    key: "E19",
    fee: "$1500",
    boothType_zh: "标准展位",
    color:'green'
  },
  {
    text: "E20",
    key: "E20",
    fee: "$1500",
    boothType_zh: "标准展位",
    color:'green'
  },
  {
    text: "E21",
    key: "E21",
    fee: "$1500",
    boothType_zh: "标准展位",
    color:'green'
  },
  {
    text: "E22",
    key: "E22",
    fee: "$1500",
    boothType_zh: "标准展位",
    color:'green'
  },
  {
    text: "E23",
    key: "E23",
    fee: "$1500",
    boothType_zh: "标准展位",
    color:'green'
  },
  {
    text: "E24",
    key: "E24",
    fee: "$1500",
    boothType_zh: "标准展位",
    color:'green'
  },
  {
    text: "E25",
    key: "E25",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E26",
    key: "E26",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E27",
    key: "E27",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E28",
    key: "E28",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E29",
    key: "E29",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E30",
    key: "E30",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E31",
    key: "E31",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E32",
    key: "E32",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E33",
    key: "E33",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E34",
    key: "E34",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E35",
    key: "E35",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E36",
    key: "E36",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E37",
    key: "E37",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E38",
    key: "E38",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E39",
    key: "E39",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E40",
    key: "E40",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E41",
    key: "E41",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E42",
    key: "E42",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E43",
    key: "E43",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E44",
    key: "E44",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E45",
    key: "E45",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E46",
    key: "E46",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E47",
    key: "E47",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E48",
    key: "E48",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E49",
    key: "E49",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E50",
    key: "E50",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E51",
    key: "E51",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E52",
    key: "E52",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E53",
    key: "E53",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "E54",
    key: "E54",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D01",
    key: "D01",
    fee: "72000",
    boothType_zh: "特装展位",
    color:'brown'
  },
  {
    text: "D02",
    key: "D02",
    fee: "36000",
    boothType_zh: "特装展位",
    color:'deepOrange'
  },
  {
    text: "D03",
    key: "D03",
    fee: "36000",
    boothType_zh: "特装展位",
    color:'deepOrange'
  },
  {
    text: "D04",
    key: "D04",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D05",
    key: "D05",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D06",
    key: "D06",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D07",
    key: "D07",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D08",
    key: "D08",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D09",
    key: "D09",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D10",
    key: "D10",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D11",
    key: "D11",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D12",
    key: "D12",
    fee: "36000",
    boothType_zh: "特装展位",
    color:'deepOrange'
  },
  {
    text: "D13",
    key: "D13",
    fee: "36000",
    boothType_zh: "特装展位",
    color:'deepOrange'
  },
  {
    text: "D14",
    key: "D14",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D15",
    key: "D15",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D16",
    key: "D16",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D17",
    key: "D17",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D18",
    key: "D18",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D19",
    key: "D19",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D20",
    key: "D20",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D21",
    key: "D21",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D22",
    key: "D22",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D23",
    key: "D23",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D24",
    key: "D24",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D25",
    key: "D25",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D26",
    key: "D26",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D27",
    key: "D27",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D28",
    key: "D28",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D29",
    key: "D29",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D30",
    key: "D30",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D31",
    key: "D31",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D32",
    key: "D32",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D33",
    key: "D33",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D34",
    key: "D34",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D35",
    key: "D35",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D36",
    key: "D36",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D37",
    key: "D37",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D38",
    key: "D38",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D39",
    key: "D39",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D40",
    key: "D40",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "D41",
    key: "D41",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C01",
    key: "C01",
    fee: "72000",
    boothType_zh: "特装展位",
    color:'brown'
  },
  {
    text: "C02",
    key: "C02",
    fee: "36000",
    boothType_zh: "特装展位",
    color:'deepOrange'
  },
  {
    text: "C03",
    key: "C03",
    fee: "36000",
    boothType_zh: "特装展位",
    color:'deepOrange'
  },
  {
    text: "C04",
    key: "C04",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C05",
    key: "C05",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C06",
    key: "C06",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C07",
    key: "C07",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C08",
    key: "C08",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C09",
    key: "C09",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C10",
    key: "C10",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C11",
    key: "C11",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C12",
    key: "C12",
    fee: "36000",
    boothType_zh: "特装展位",
    color:'deepOrange'
  },
  {
    text: "C13",
    key: "C13",
    fee: "36000",
    boothType_zh: "特装展位",
    color:'deepOrange'
  },
  {
    text: "C14",
    key: "C14",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C15",
    key: "C15",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C16",
    key: "C16",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C17",
    key: "C17",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C18",
    key: "C18",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C19",
    key: "C19",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C20",
    key: "C20",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C21",
    key: "C21",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C22",
    key: "C22",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C23",
    key: "C23",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C24",
    key: "C24",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C25",
    key: "C25",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C26",
    key: "C26",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C27",
    key: "C27",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C28",
    key: "C28",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C29",
    key: "C29",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C30",
    key: "C30",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C31",
    key: "C31",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C32",
    key: "C32",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C33",
    key: "C33",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C34",
    key: "C34",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C35",
    key: "C35",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C36",
    key: "C36",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C37",
    key: "C37",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "C38",
    key: "C38",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B01",
    key: "B01",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B02",
    key: "B02",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B03",
    key: "B03",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B04",
    key: "B04",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B05",
    key: "B05",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B06",
    key: "B06",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B07",
    key: "B07",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B08",
    key: "B08",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B09",
    key: "B09",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B10",
    key: "B10",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B11",
    key: "B11",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B12",
    key: "B12",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B13",
    key: "B13",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B14",
    key: "B14",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B15",
    key: "B15",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B16",
    key: "B16",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B17",
    key: "B17",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B18",
    key: "B18",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B19",
    key: "B19",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B20",
    key: "B20",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B21",
    key: "B21",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B22",
    key: "B22",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B23",
    key: "B23",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B24",
    key: "B24",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B25",
    key: "B25",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B26",
    key: "B26",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B27",
    key: "B27",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B28",
    key: "B28",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B29",
    key: "B29",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B30",
    key: "B30",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B31",
    key: "B31",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B32",
    key: "B32",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B33",
    key: "B33",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B34",
    key: "B34",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B35",
    key: "B35",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B36",
    key: "B36",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B37",
    key: "B37",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B38",
    key: "B38",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B39",
    key: "B39",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B40",
    key: "B40",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B41",
    key: "B41",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B42",
    key: "B42",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B43",
    key: "B43",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B44",
    key: "B44",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B45",
    key: "B45",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B46",
    key: "B46",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B47",
    key: "B47",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B48",
    key: "B48",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B49",
    key: "B49",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B50",
    key: "B50",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B51",
    key: "B51",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B52",
    key: "B52",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B53",
    key: "B53",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B54",
    key: "B54",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B55",
    key: "B55",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B56",
    key: "B56",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B57",
    key: "B57",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B58",
    key: "B58",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B59",
    key: "B59",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "B60",
    key: "B60",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "A01",
    key: "A01",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A02",
    key: "A02",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A03",
    key: "A03",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A04",
    key: "A04",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A05",
    key: "A05",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A06",
    key: "A06",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A07",
    key: "A07",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A08",
    key: "A08",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A09",
    key: "A09",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A10",
    key: "A10",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A11",
    key: "A11",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A12",
    key: "A12",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A13",
    key: "A13",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A14",
    key: "A14",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A15",
    key: "A15",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A16",
    key: "A16",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A17",
    key: "A17",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A18",
    key: "A18",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A19",
    key: "A19",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A20",
    key: "A20",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A21",
    key: "A21",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A22",
    key: "A22",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A23",
    key: "A23",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A24",
    key: "A24",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A25",
    key: "A25",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A26",
    key: "A26",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A27",
    key: "A27",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A28",
    key: "A28",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A29",
    key: "A29",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "A30",
    key: "A30",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "H01",
    key: "H01",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "H02",
    key: "H02",
    fee: "8800",
    boothType_zh: "标准展位",
    color:'blue'
  },
  {
    text: "H03",
    key: "H03",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "H04",
    key: "H04",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "H05",
    key: "H05",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "H06",
    key: "H06",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "H07",
    key: "H07",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
  {
    text: "H08",
    key: "H08",
    fee: "9800",
    boothType_zh: "标准展位",
    color:'orange'
  },
];

export const arrow = [
  // {
  //   text: "el-icon-bottom arrowOne",
  //   key: "arrowOne"
  // },
  // {
  //   text: "el-icon-top arrowTwo",
  //   key: "arrowTwo"
  // },
  // {
  //   text: "el-icon-top arrowThree",
  //   key: "arrowThree"
  // },
  // {
  //   text: "el-icon-bottom arrowFour",
  //   key: "arrowFour"
  // },
  // {
  //   text: "el-icon-back arrowFive",
  //   key: "arrowFive"
  // },
  // {
  //   text: "el-icon-right arrowSix",
  //   key: "arrowSix"
  // },
  // {
  //   text: "el-icon-top arrowSeven",
  //   key: "arrowSeven"
  // },
  // {
  //   text: "el-icon-bottom arrowEight",
  //   key: "arrowEight"
  // },
  // {
  //   text: "el-icon-top arrowNine",
  //   key: "arrowNine"
  // },
  // {
  //   text: "el-icon-bottom arrowTen",
  //   key: "arrowTen"
  // },
  // {
  //   text: "el-icon-top arrowEveven",
  //   key: "arrowEveven"
  // },
  // {
  //   text: "el-icon-back arrowTwelve",
  //   key: "arrowTwelve"
  // },
  // {
  //   text: "el-icon-right arrowThirteen",
  //   key: "arrowThirteen"
  // },
];

export const writing = [
  {
    text: "论坛区及开幕式",
    text_en: "论坛区及开幕式",
    key: "text1",
  },
  {
    text: "主办方办公室",
    text_en: "主办方办公室",
    key: "text2",
  },
  {
    text: "主通道",
    text_en: "主通道",
    key: "text3",
  },
  // {
  //   text: "A类标展 ￥9800",
  //   key:'text4'
  // },
  // {
  //   text: "B类标展 ￥8800",
  //   key:'text5'
  // },
  // {
  //   text: "海外标展 $1500",
  //   key:'text6'
  // },
  // {
  //   text: "36m²特装 ￥36000",
  //   key:'text7'
  // },
  // {
  //   text: "72m²特装 ￥72000",
  //   key:'text8'
  // },
  // {
  //   text:"海外代理专区",
  //   key:"text9"
  // },
  // {
  //   text:"中欧班列专区",
  //   key:"text10"
  // },
  // {
  //   text:"跨境电商专区",
  //   key:"text11"
  // },
  // {
  //   text:"关务专区",
  //   key:"text12"
  // },
  // {
  //   text:"危化品专区",
  //   key:"text13"
  // },

  // {
  //   text: "观众出入口",
  //   text_en: "Entrance/ Exit",
  //   key: "entrance",
  // },
  // {
  //   text: "货物出入口",
  //   text_en: "Goods Exit",
  //   key: "goods",
  // },
  // {
  //   text: "洗手间",
  //   text_en: "Toilets",
  //   key: "toilet",
  // },
];

export const column=[
  {
    text:'',
    key:'column1'
  },
  {
    text:'',
    key:'column2'
  },
  {
    text:'',
    key:'column3'
  },
  {
    text:'',
    key:'column4'
  },
  {
    text:'',
    key:'column5'
  },
  {
    text:'',
    key:'column6'
  },
  {
    text:'',
    key:'column7'
  },
  {
    text:'',
    key:'column8'
  },
  {
    text:'',
    key:'column9'
  },
  {
    text:'',
    key:'column10'
  },
  {
    text:'',
    key:'column11'
  },
]

export const backgroundText=[
  {
    text:"海外代理专区",
    key:"backgroundText1"
  },
  {
    text:"中欧班列专区",
    key:"backgroundText2"
  },
  {
    text:"跨境电商专区",
    key:"backgroundText3"
  },
  {
    text:"关务专区",
    key:"backgroundText4"
  },
  {
    text:"危化品专区",
    key:"backgroundText5"
  },
]
